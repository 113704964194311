@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e5e4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Last_4_digit {
  width: 294px;
  border-radius: 4px;
  border: 1px solid #c6cacc;
  float: right;
  background-color: #f1f1f1;
}
.buttonBG-related-daigonis {
  margin: 7px 0 0 43px;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  border: solid 1px #4782c4;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #4782c4;
}
.td_style1 {
  width: 149px;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #787885;
  padding-left: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
}
ol {
  margin-top: 10px !important;
}
.label {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: left;
  color: #454d58;
}
.td_style {
  width: 191px;
  height: 32px;
  margin: 0 2px 0 0;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.44px;
  text-align: left;
  color: #19191d;
}
.disabledReenrollPlanSelection {
  pointer-events: none;
  opacity: 0.8;
}
.listStyle {
  text-indent: -1.5em;
  list-style-type: disc;
  list-style-position: inside;
  padding: 5px 0px 0px 20px;
}

.EnrollNew2Weight {
  /* min-width: 150px; */
  margin-top: 10px;
  margin-left: 30px;
}
.heightDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -50px;
}
.EnrollNew2Height {
  /* min-width: 100px; */
  margin-top: 10px;
}
.EnrollNew2HeightInch {
  width: 100px;
  margin-right: 24px;
  margin-top: 20px;
}
.BMIDiv {
  display: flex;
  flex-direction: row;
}
.BMIMemberName {
  text-align: left;
  padding: 40px 10px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: bold;
  /* line-height: 4.5px; */
  margin-top: -19px;
}
.visitDiv {
  display: flex;
  flex-direction: row;
}
.visitContainer {
  display: flex;
  flex-direction: row;
  margin-left: -50px;
}
.selectWidth {
  width: 100% !important;
}
.disqualifiedWrp {
  width: 100%;
  max-height: 400px;
  display: flex;
}
.disqfTextWrp {
  width: 40%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.disqfTableWrp {
  font-size: 14px;
  line-height: 16px;
  max-height: 400px;
}
.summTable {
  padding-left: 10px;
  padding-right: 25px;
  table-layout: "fixed";
  padding-bottom: 15px;
  padding-top: 15px;
  min-width: 95%;
  flex-basis: 100%;
}

@media screen and (max-width: 600px) {
  .EnrollNew2Weight {
    width: 90px;
    margin-left: 10px;
  }
  .BMIDiv {
    display: flex;
    flex-direction: column;
  }
  .BMIMemberName {
    padding: 30px 0px 0px 10px;
    width: 100%;
  }
  .visitDiv {
    display: flex;
    flex-direction: column;
  }
  .visitContainer {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  .seenField {
    margin-left: -5px !important;
  }
  .selectWidth {
    width: 100% !important;
  }
  .disqualifiedWrp {
    flex-direction: column;
  }
  .disqfTextWrp {
    width: 100%;
  }
  .disqfTableWrp {
    max-height: 300px;
  }
  .summTable {
    padding-left: 0px;
    padding-right: 0px;
    min-width: 100%;
  }
  .ModalStyle{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 80% !important;
    font-style: Lato;
    height: 300px;
    background-color: "background.paper";
    padding: 0;
    box-shadow: 24;
}
.footerFont{
    font-size: 8px;
}
}
.MuiStepLabel-label.MuiStepLabel-active{
font-weight: 600 !important;
}
.ModalStyle{
position: absolute;
top: 45%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
width: 57%;
font-style: Lato;
background-color: "background.paper";
padding: 0;
box-shadow: 24;
}
iframe{
position: inherit !important;
}

.ModalStyleVerification{
position: absolute;
top: 45%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
width: 337px;
font-style: Lato;
background-color: "background.paper";
padding: 0;
box-shadow: 24;
}

.EnrollFamily_textAlign__2oLfq {
  text-align: left;
  color: black;
  margin-left: 0px;
  margin-top: 0px;
}
.EnrollFamily_textAlign1__2RCrC {
  color: rgba(0, 0, 0, 0.87);
}
.EnrollFamily_container__3qz-O {
  display: flex;
}
.EnrollFamily_notification__3r2rU {
  /*padding-left: 2%;*/
  width: 30%;
  color: rgba(0, 0, 0, 0.89);
  font-size: 14px;
  line-height: 25px;
  display: inline-block;
}
.EnrollFamily_helpText__3cMGb {
  color: #304d63;
  font-size: 14px;
  line-height: 16px;
  margin-left: 34.7%;
  text-align: right;
}
/* .names{
     
     margin-top: 0px;
     width: 110px;
     height: 16px;
     font-family: Roboto;
     font-size: 16px;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: 1;
     letter-spacing: normal;
     color: rgba(0, 0, 0, 0.87);
     margin-right:1px;
 } */
.EnrollFamily_nameTitle__3nTWQ {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  margin-top: 10px;
  color: #4f4f4f;
}

.EnrollFamily_name__2iCM9 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 16px;
  letter-spacing: normal;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.89);
}

.EnrollFamily_status__1K_Pn {
  width: 300px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  color: #4f4f4f;
}
.EnrollFamily_Name__1SVBJ {
  margin-top: 25px;
  width: 38px;
  height: 19px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #4f4f4f;
  margin-left: 5px;
}
.EnrollFamily_cont__1ZKRC {
  width: 975px;
  height: 359px;
}

.EnrollFamily_placeText__wADJp {
  width: 70%;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.44px;
}

.addOnTable table tbody tr th {
    /* background: #FAE6BE; */
}

.addOnTable table tbody tr td {
    /* background: #FAE6BE; */

}

.addOnTable table tbody tr th span {
    color: #420045;
}

.addOnTable table tbody tr td span {
    /* color: #420045; */
    color: #132244;

}

.MuiBadge-colorSecondary {
    background-color: #fdb315 !important;
}

.addOnTable table tbody tr:last-child th {
    /* background: #FEBF42; */
    background: #fdb315;
}

.addOnTable table tbody tr:last-child td {
    background: #fdb315;
}

.smallTable table thead tr th:nth-child(1) {
    width: 40px;
}

.smallTable table thead tr th:nth-child(2) {
    width: 160px;
}

.smallTable table thead tr th:nth-child(13) {
    width: 100px;
}

.smallTable table tbody tr td:first-child span {
    padding: 0;
    margin-left: 0 !important;
}

.reviewAddon table thead tr th:nth-child(1) {
    width: 150px;
}

.reviewAddon table thead tr th:last-child {
    width: 90px;
}

.familyDetailsTable table thead tr th:nth-child(1) {
    width: 150px;
}

.reivewTable table tbody tr th {
    /* background: #FAE6BE; */
}

.reivewTable table tbody tr td {
    /* background: #FAE6BE; */
}

.reivewTable table tbody tr th span {
    color: #420045;
}

.reivewTable table tbody tr td span {
    color: #420045;
}

/* .reivewTable table th, .reivewTable table td {
    border: 1px solid #ededed;
} */
.reivewTable table tbody tr td:last-child {
    /* background: #F0C8A0; */
    width: 72px;
}

.reivewTable table tbody tr:last-child th {
    /* background: #FEBF42; */
    background: #f2b543;
}

.reivewTable table tbody tr:last-child td {
    /* background: #FEBF42; */
    background: #f2b543;
}

.reivewTable table tbody tr:last-child td:last-child {
    /* background: #F48366; */
    background: #f2b543;
}

.reviewChoice table thead tr th:first-child {
    width: 180px;
}

.popupTable table thead tr th:first-child {
    width: 152px !important;
}

.modal-open .modal-backdrop:nth-child(n-1) {
    z-index: 1050;
}

.reEnrollTable table tbody tr:nth-last-child(2) th {
    background: #FEBF42;
}

.reEnrollTable table tbody tr:nth-last-child(2) td {
    background: #FEBF42;
}

.reEnrollTable table tbody tr:nth-last-child(2) td:last-child {
    background: #F48366;
}

.addOnDropDown {
    width: 20vw;
    height: 37px;
    margin: 5px 29px 0 23px;
    padding: 6.6px 10px 6px 12px;
    border-radius: 4px;
    border: solid 1px #c6cacc;
    background-color: #f1f8e5;
}
/* .selectProgStep div:first-child > span svg {
    color: rgba(0, 0, 0, 0.38) !important;
}
.setupFamilyStep div:nth-child(2) > span svg {
    color: rgba(0, 0, 0, 0.38) !important;
} */

.accountNumDiv {
  margin-bottom: -45px;
}
#s input[type="text"]:disabled,
#fam input[type="text"]:disabled {
  opacity: 0.5;
}
#fam .MuiInputBase-input.Mui-disabled {
  opacity: 0.5;
}
.effectiveDateSection {
  width: 85%;
  font-size: 15px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background: #fee6c2;
  padding: 8px 0px;
  border-radius: 8px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 30px;
}
.effectiveDateSection div {
  padding: 0 0 0 15px;
}
.paymentDatesWrapper {
  width: 85%;
  font-size: 15px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.paymentDatesWrapper h5 {
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.paymentDatesWrapper h6 {
  font-size: 12px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 2px 0;
  text-indent: -5px;
}
.paymentDatesWrapper .detailsTableHeader {
  background: #dddddd;
}
.detailsTableHeader .detailsTableRow {
  font-weight: bold;
}
.paymentDatesWrapper .detailsTable {
  margin-left: 0;
  margin-right: 0;
}
.paymentDatesWrapper .detailsTable .detailsTableRow {
  padding: 5px 0 5px 15px;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
}
.paymentDatesWrapper .detailsTable .detailsTableRow:last-child {
  border-right: 1px solid #dddddd;
}
@media screen and (max-width: 450px) {
  .accountNumDiv {
    margin-bottom: 45px;
  }
  .effectiveDateSection {
    width: 100%;
  }
  .effectiveDateSection div {
    padding: 0 10px 0 15px;
    text-align: left;
  }
  .paymentDatesWrapper {
    width: 100%;
  }
  .paymentDatesWrapper .detailsTable .detailsTableRow {
    padding: 5px 2px 5px 10px;
    text-align: left;
  }
}

.login {
  /* background: gray; */
}
.logo-main {
  text-align: center;
}
.NetWellPortalLoginDesktop {
  width: 100%;
  /* height: 100%; */
  flex-grow: 0;
  /* padding: 0px 0 133px; */
  /* border: solid 1px #000; */
  background-color: #fff;
}
.NetWellPortalLoginMobile {
  width: 100%;
  height: 100vh;
  flex-grow: 0;
  /* padding: 0px 0 133px; */
  /* border: solid 1px #000; */
  background-color: #fff;
}
.familylogo-custom {
  /* max-width: 100%;
    max-height: 60%; */
  /* width: 100%;    
    height: 378px; */
  background-image: url(/static/media/family-looking-at-tablet.2d8b44bd.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 2.8;
}
.familylogo-customMob {
  /* max-width: 100%;
    max-height: 60%; */
  /* width: 100%;    
    height: 378px; */
  background-image: url(/static/media/family-looking-at-tablet_1.e64324d2.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 1;
}
.main-form-login {
  /* background:#EAE8DB; */
  padding: 16px 12px 0px;
  /* margin-top: 20px; */
}
.main-form-loginMob {
  /* background:#EAE8DB; */
  padding: 16px 12px 0px;
  margin-top: 30px;
  margin-left: 10%;
}
.a-input-login {
  width: 374px;
  height: 36px;
  flex-grow: 0;
  margin: 0 44px 0px 0;
  padding: 15px 52px 16px 32px;
  border-radius: 100px;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);
}
.a-input-loginMob {
  width: 370px;
  /* height: 36px; */
  flex-grow: 0;
  margin: 0 7px 0px 0;
  padding: 16px 43px 16px 30px;
  border-radius: 100px;
  border: solid 1px #89969f;
  background-color: rgba(65, 181, 194, 0);
}
.a-btn2-login {
  width: 176px;
  height: 36px;
  flex-grow: 0;
  margin: 0 20px 0 20px;
  border-radius: 50px;
  color: #fff;
  background-color: #4782c4;
  border: solid 1px #89969f;
}
.a-btn2-loginMob {
  width: 370px;
  /* height: 36px; */
  flex-grow: 0;
  margin: 0 20px 0 20px;
  padding: 16px 43px 16px 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background-color: #4782c4;
  border: solid 1px #89969f;
}
.a-btn2-login:hover {
  border: solid 2px black;
}
.contain22er {
  margin-top: 20px;
}
.bodyColor {
  background: gray !important;
}

.login .login-card {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
  border-radius: 0% !important;
  /* width: 350px;
    height: 450px; */
}

.logo {
  text-align: center;
}

.logo-customLogin {
  width: 209px;
  margin: 20px 0px 20px 10px;
}
.main-form {
  background: #eae8db;
  padding: 20px;
  margin-top: 20px;
}
.label-head {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: black;
  font-weight: 400;
}

.a-input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
  transition:
    border-color 0.15s ease-in-out 0s,
    box-shadow 0.15s ease-in-out 0s;
  line-height: 1.42857;
}

.login form p {
  margin-bottom: 5px;
}

.a-form-ctrl {
  margin-bottom: 5px;
}

.forgot-pawd {
  color: #337ab7;
  cursor: pointer;
}
.forgot-pawd a {
  color: #337ab7;
  cursor: pointer;
}
.forgot-pawd:hover {
  text-decoration: underline;
}

.a-btn {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 100%;
  color: #fff;
  background-color: #2e6da4;
  border-color: #2e6da4;
  background-image: none;
  border: 1px solid #2e6da4;
  border-radius: 4px;
}

.a-btn1 {
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
  height: 40px;
  width: 220%;
  color: #fff;
  background-color: #fb6647;
  border-color: #2e6da4;
  background-image: none;
  border: 1px solid #2e6da4;
  border-radius: 4px;
}
.a-btn:hover {
  color: #fff;
  background-color: #febf42;
}

.pwd-validations .valid {
  color: #19bf00;
}
.pwd-validations .invalid {
  color: #df3312;
}
.pwd-validations .pwd-validation-txt {
  margin-left: 5px;
}

.a-errorMessage {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  background: #f5f5f5;
  border: 2px solid #d64958;
  color: #d64958;
  margin-bottom: 10px;
  font-weight: 400;
}

.autologin-err-msg {
  padding: 5px;
  font-size: 14px;
  width: 85%;
  color: #d64958;
  margin-bottom: 10px;
  font-weight: 400;
}

.login button.a-btn[disabled] {
  color: #ffffff85;
  pointer-events: none;
  background: #2e6da4;
}

.a-pwd-visibility {
  margin: 6px 10px 0 -38px;
}
.a-pwd-div {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}
/* ==============================Loginform.js================= */
/*
.loginform{
    border:1px solid red;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background-color: #ffffff;
    !* background-color: #27ae60; *!
}

.loginform_container{
    border:1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_container{
    border:1px solid red;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login_image_container{
    !* border:1px solid red; *!
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.login_uhs_logo{
    margin-top: 30px;
    margin-left: -200px;
    height: 68px;
    object-fit: contain;
    !* width: 252px; *!
}
.login_main_image{
    height: 553px;
    object-fit: contain;
}
.login_input_container{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    !* border: 1px solid red; *!
}
.login_input_username{
    outline-width: 0px;
    border: none;
    padding: 20px 20px;
    !* border:1px solid #89969f; *!
    width: 374px;
    height: 66px;
    border-radius: 100px;
    border: solid 1px #89969f;
    background-color: rgba(65, 181, 194, 0);

    !* font-family: 'Tungsten'; *!
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.2;
    letter-spacing: 1.2;
    color: #89969f;

    margin-right: 30px;
}

.login_button{
    width: 176px;
    height: 65px;
    border-radius: 50px;
    background-color: #e9716f;
    outline-width: 0px;
    border: none;
    color: #ffffff;
    margin-bottom: 20px;
}
.login_error{
    font-size: 14px;
    color:red;
    margin-left: 20px;
    margin-top: 5px;
}

.login_button:hover{
    border: none;
    outline: none;
}
.login_button:focus{
    border: none;
    outline: none;
}
.login_bottom_text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.login_bottom_subtext{
    !* width: 863px; *!
    !* height: 51px; *!
    !* font-family: Roboto; *!
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.40;
    letter-spacing: normal;
    color: #000000;
}
.login_plese_text{
    font-weight: 500;
    color: #e9716f;
}
*/
.MuiPhoneNumber-flagButton {
  display: none !important;
}
.MuiFilledInput-adornedStart {
  padding-left: 0px !important;
  background-color: white !important;
}
.MuiFilledInput-input {
  padding: 7px 0px !important;
  /* background: #fff !important; */
  border-bottom: transparent !important;
}
.myPhone {
  /* background: #fff !important; */
  width: 100%;
  border-radius: 5px;
  border: 2px solid #524f4f !important;
  /* height: 50px; */
}

/* Header right logo- */

.right_logo {
  width: 100px;
}

/* Web footer-------chat button and text */

.rightAlign {
  text-align: right;
}

.chat_footer h5 {
  color: #3ec7f1;
  margin-top: 35px;
  font-size: 18px;
}
.chat_footer a {
  color: #3ec7f1;
  text-decoration: underline;
}
.chat_footer p {
  margin: 0;
  font-size: 14px;
}
.h5_tag {
  margin-top: 0px;
}

/* Details Page ---- */

.video_tag {
  width: 400px;
  height: 210px;
  background-color: lightgray;
}

/* Home page css--- */
.web_container {
  padding: 20px 30px;
  background-color: white;
}

.right_logo {
  width: 100px;
}
.h4_text {
  margin-top: 50px;
}
.p_tag {
  margin-top: 15px;
}

.form_tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
.button_tag {
  margin-top: 40px;
}

.section_wrap {
  flex-wrap: nowrap !important;
}
.mainWrapper {
  top: 0;
  position: relative;
  padding: 0 25px;
  /* margin-top: -28vh;
    margin-left: 1vw; */
}

.web_container h4 {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #f7007d;
}

.web_container p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.row-m-t {
  margin-top: 10px;
}

.helperText {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.textBold {
  font-weight: bold;
}
.welcome_download_logo {
  margin-left: 15px;
  height: 42px;
  width: 42px;
  object-fit: contain;
  cursor: pointer;
}
/*------ Message modal  --------------*/
.msgModalWrapper .modal-header {
  background: #33afb0;
}
.msgModalWrapper .modal-header .modal-title {
  background: #33afb0;
}
.msgModalWrapper .modal-footer .btn-ok {
  background: #febf42;
  padding: 8px 20px;
  font-weight: 500;
  font-size: 14px;
}
.popupMsgWrapper {
  padding: 10px 0;
}
.popupMsgWrapper p {
  margin: 0;
}

.contentWrapper {
  margin-top: 50px;
  min-height: 450px;
}
.amountText {
  font-size: 24px;
  color: #27ae60;
  margin: 10px 0;
}
.frameBackground {
  background-color: #e3e5e5;
  padding: 10px 28px;
  border-radius: 6px;
  margin: 30px 0px 0px 0px;
  z-index: 0;
  position: relative;
}
.frameBackground_Agent {
  background-color: #cccccc;
  padding: 10px 28px;
  border-radius: 6px;
  margin: -10px 0 0 0;
  z-index: -1;
}
.callAgent {
  flex-grow: 0;
  margin: 0 0 5px 0;
  /* font-family: Candara; */
  font-size: 20px;
  /* font-weight: bold; */
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.submitBtn button:disabled {
  background-color: #d3d3d3;
}
.chat_footer {
  margin-top: 20px;
}
.footerBackColor {
  background-color: lightgray;
  padding: 10px;
}
.footerBackColor p {
  margin: 0px;
  font-size: 14px;
}

/*--=== Media Query 
=============================================--*/
@media only screen and (max-width: 460px) {
  .web_container {
    padding: 20px 15px;
  }
  .contentWrapper {
    margin-top: 30px;
  }
  .left_logo {
    width: 120px;
  }
  .right_logo {
    width: 65px;
  }
  .h4_text {
    margin-top: 30px;
  }
  .button_tag {
    margin-top: 30px;
  }
}

